











import { Component, Vue } from 'vue-property-decorator';
import IconMessage from '@/shared/resources/components/IconMessage.vue';

@Component({
  components: {
    IconMessage,
  },
})
export default class AuthRegisterContractorHasActiveAccount extends Vue {

}
